import React, { Component } from 'react';
import './App.css';
import {Link, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './views/home.js';
import GoogleMap from './googleMapScript';
class App extends Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;
    this.state = {};
  }
  callback() {
    this.setState({ mapinit: true });
  }

  render() {
    return (
      <div >
        <GoogleMap API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />
        <Router>
          <Switch>
          <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/angebot" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/produkte" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/preise" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/mannschaft" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/kontakt" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />

          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
