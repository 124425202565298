import React, { Component } from 'react';
import instagram from '../images/instagram.png';
import facebook from '../images/fb.png';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container
} from 'reactstrap';


export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar className="navbar fixed-top" expand="lg">
          <NavbarBrand href="/"><img src={logo} className="img-fluid logo" /></NavbarBrand>
          <NavbarToggler onClick={this.toggle}><i className="fa fa-bars"></i></NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Container>

              <Nav className="mx-auto" navbar>
                <NavItem >
                  <Link to='/angebot' className={this.props[0].location.pathname === "/angebot" ? "nav-link activ" : "nav-link"}>ANGEBOT</Link>
                </NavItem>
                <NavItem>
                  <Link to='/produkte' className={this.props[0].location.pathname === "/produkte" ? "nav-link activ" : "nav-link"}>PRODUKTE</Link>
                </NavItem>
                <NavItem>
                  <Link to='/preise' className={this.props[0].location.pathname === "/preise" ? "nav-link activ" : "nav-link"}>PREISE</Link>
                </NavItem>
                <NavItem>
                  <Link to='/mannschaft' className={this.props[0].location.pathname === "/mannschaft" ? "nav-link activ" : "nav-link"}>ÜBER MICH</Link>
                </NavItem>
                <NavItem>
                  <Link to='/kontakt' className={this.props[0].location.pathname === "/kontakt" ? "nav-link activ" : "nav-link"}>KONTAKT</Link>
                </NavItem>
              </Nav>
            </Container>
            <div className="socialIcon">
              <a href="https://www.facebook.com/Coiffeur-Branka-470446853304402/" target="_blank"><img src={facebook} className="img-fluid facebook" /></a>
              <a href="https://www.instagram.com/coiffeurbranka/" target="_blank"><img src={instagram} className="img-fluid instagram" /></a>
            </div>

          </Collapse>

        </Navbar>

      </div>
    );
  }
}